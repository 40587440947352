<template functional>
  <svg
    :width="props.size"
    :height="props.size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polyline
      points="24.48 11.76 16 20.24 7.51 11.76"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: { type: String, default: "16" },
    iconColor: { type: String, default: "var(--color-pop-01)" },
  },
};
</script>

<style></style>
