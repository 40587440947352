import { environment } from "../../environments/environment";
import Vue from "vue";
import { HubConnectionBuilder, HubConnection } from "@microsoft/signalr";

const instances: { [hubUrl: string]: SignalRSocketHandler } = {};

export class SignalRSocketHandler {
  socket!: HubConnection;
  status: SignalRSocketStatus = Vue.observable({
    connected: false,
    error: false,
    message: "",
  });
  hasBeenConnected = false;
  subscribers: { [key: string]: Array<([arg0]: any) => void> } = {};

  constructor(url?: string) {
    if (url && !instances[url]) {
      console.info("SignalR attempt to connect url: ", url);
      this.socket = new HubConnectionBuilder().withUrl(url).build();
      this.signalStatusChange.bind(status);
      instances[url] = this; // Avoid creating a new connection to same url when already exists
      this.connect();
    } else if (!url) {
      throw new Error("First instantiation need to define url to signalR hub");
    }

    return instances[url];
  }

  /**
   * Connect to signalR hub
   */
  async connect() {
    if (!this.status.connected) {
      try {
        if (!this.hasBeenConnected) {
          // First time connecting
          this.socket.onclose(async () => {
            console.error("SignalR connection closed, trying to reconnect.");
            this.status.connected = false;
            this.signalStatusChange();
            this.connect();
          });
          this.socket.onreconnecting(() => {
            console.error("Disconnected from signalR, trying to reconnect.");
          });
        }
        await this.socket.start();
        console.info("Connected to signalR");
        this.status.connected = true;
        this.status.error = false;
        this.status.message = "";

        if (this.hasBeenConnected) {
          // This is a reconnection => we should therefor reload all application data
          this.emit("reconnect");
        }
        this.signalStatusChange();
        this.hasBeenConnected = true;
      } catch (error) {
        if (error instanceof Error && error.message == "Forbidden") {
          // Cookie has expired
          // Logout
          console.error("Cookie has expired");
          if ((window as any).destroySessionAndGoToLogin) {
            (window as any).destroySessionAndGoToLogin();
          }
          throw new Error("403");
        }

        this.status.error = true;
        this.status.message = error instanceof Error ? error.message : "Unknown error";
        this.status.connected = false;
        this.signalStatusChange();

        setTimeout(() => {
          this.connect();
        }, 2000);
      }
    }
  }

  /**
   * Assign a signalR hub listener
   */
  on(methodName: string, cb: (...args: any[]) => void) {
    this.socket?.on(methodName, cb);
  }

  signalStatusChange() {
    this.emit("statuschange", this.status);
  }

  subscribe(topic: string, cb: (newStatus: SignalRSocketStatus) => void) {
    if (
      Object.prototype.hasOwnProperty.call(this.subscribers, topic) &&
      Array.isArray(this.subscribers[topic])
    ) {
      this.subscribers[topic].push(cb);
    } else {
      this.subscribers[topic] = [cb];
    }
  }
  unsubscribe(topic: string, cb?: (newStatus: SignalRSocketStatus) => void) {
    if (
      cb &&
      Object.prototype.hasOwnProperty.call(this.subscribers, topic) &&
      Array.isArray(this.subscribers[topic])
    ) {
      this.subscribers[topic] = this.subscribers[topic].filter((fn) => fn != cb);
    } else {
      delete this.subscribers[topic];
    }
  }

  emit(topic: string, payload?: any) {
    const subscribers = this.subscribers[topic];
    if (subscribers) {
      subscribers.forEach((fn) => {
        fn(payload);
      });
    }
  }

  /**
   * Invoke method on backend server hub
   * @example signalRClient.invoke('SubscribeToOrganization', "organization-id-1");
   */
  invoke(topic: string, payload?: unknown) {
    if (payload) {
      this.socket?.invoke(topic, payload);
    } else {
      this.socket?.invoke(topic);
    }
  }

  /**
   * Disconnect signalR hub
   */
  async disconnect() {
    console.info("Stopping SignalR connection.");
    await this.socket?.stop();
    this.status.connected = false;
  }
}

interface SignalRSocketStatus {
  connected: boolean;
  error: boolean;
  message: string;
}

export default new SignalRSocketHandler(environment.signalRSocket);
