var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"table-item",class:{ selected: _vm.isSelected }},[_c('div',{staticClass:"table-item-data",on:{"click":function($event){return _vm.$emit('itemClick')}}},_vm._l((_vm.properties),function(property,index){return _c('div',{key:index,style:(property.style)},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content:
            _vm.getFormattedContent(_vm.item[property.property]).length > 20
              ? _vm.getFormattedContent(_vm.item[property.property])
              : '',
          placement: 'bottom-center',
        }),expression:"{\n          content:\n            getFormattedContent(item[property.property]).length > 20\n              ? getFormattedContent(item[property.property])\n              : '',\n          placement: 'bottom-center',\n        }"}],domProps:{"innerHTML":_vm._s(_vm.getFormattedContent(_vm.item[property.property], true))}})])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }