<template>
  <div class="wearable-admin">
    <LoadingAnimation v-if="loading" />
    <STwoColumnLayout v-else :isSecondaryOpen="selectedWearableMac !== ''">
      <template #primary>
        <div class="table-list-wrapper">
          <div class="table-list-header">
            <data-table-header
              v-if="!isMobile"
              style="height: 62px; background-color: var(--color-base-ui)"
              :headers="headers"
              color="#2291A0"
              iconSize="24"
              @sortBy="setSortProperty"
              :sortProperty="sortProperty"
              :sortDir="sortDirection"
              :allSelected="checkedItems.length === wearables.length"
              :partlySelected="checkedItems.length > 0 && checkedItems.length !== wearables.length"
              @toggleCheckboxes="toggleCheckboxes"
            />
            <SSearch
              v-else
              :modelValue="searchText"
              @update:modelValue="setSearchText"
              :placeholder="searchBarPlaceholder"
            />
          </div>
          <ul class="table-items">
            <wearable-table-item
              v-for="wearable in filteredWearables"
              :key="wearable.id"
              :wearable="wearable"
              :person="getPersonByWearableMac(wearable.mac)"
              :checked="checkedItems.indexOf(wearable.mac) !== -1"
              :isSelected="selectedWearableMac !== '' && selectedWearableMac === wearable.mac"
              :searchText="searchText"
              :properties="headers"
              @checkboxChange="handleCheckbox"
              @wearableClick="setSelectedItem(wearable)"
            />
          </ul>
        </div>
      </template>
      <template #secondary>
        <div class="right-bar">
          <wearable-summary
            v-show="selectedWearableMac === ''"
            :wearables="wearables"
            :checkedItems="checkedItems"
            @showModal="handleShowModal"
          />
          <wearable-details
            v-if="selectedWearableMac !== ''"
            :item="selectedItem"
            @close="closeSelectedDetails"
            @showModal="handleShowModal"
          />
        </div>
      </template>
    </STwoColumnLayout>

    <portal to="top-bar">
      <TopBar
        :isSearchEnabled="!isMobile"
        :searchTextPlaceholder="searchBarPlaceholder"
        :filters="!isMobile ? filters : undefined"
        :searchText="searchText"
        :mainSnapshot="mainSnapshot"
        :mustering="!!musteringEvent"
        @toggleFilter="toggleFilter"
        @setSearchText="setSearchText"
        v-on="$listeners"
      />
    </portal>
  </div>
</template>

<script>
import DataTableHeader from "@/components/DataTableHeader";
import WearableDisconnectedIcon from "@/components/icons/WearableDisconnected";
import PersonIcon from "scanreach-frontend-components/src/components/icons/PersonIcon.vue";
import WearableTableItem from "@/components/desktop/WearableAdmin/WearableTableItem";
import WearableDetails from "@/components/desktop/WearableAdmin/WearableDetails";
import WearableSummary from "@/components/desktop/WearableAdmin/WearableSummary";
import { getWearableShortMac } from "@/helpers/hardwareHelpers";
import { mapState, mapGetters } from "vuex";
import TopBar from "@/components/desktop/TopBar";
import LoadingAnimation from "@/components/desktop/LoadingAnimation";
import Antenna from "scanreach-frontend-components/src/components/icons/AntennaIcon4.vue";
import BatteryIcon100 from "scanreach-frontend-components/src/components/icons/BatteryIcon100.vue";
import Temperature from "scanreach-frontend-components/src/components/icons/TemperatureIcon.vue";
import SCheckbox from "scanreach-frontend-components/src/components/SCheckbox.vue";
/**@typedef {import('@/typedef').DashboardWearable} DashboardWearable */
import STwoColumnLayout from "@/components/desktop/STwoColumnLayout.vue";
import SSearch from "scanreach-frontend-components/src/components/SSearch.vue";
import useScreenSize from "@/compositions/useScreenSize";
import { SortDir } from "../../compositions/useSort";
import { nodeAndWearableSort } from "@/helpers/utils";

export default {
  name: "WearableAdmin",

  components: {
    DataTableHeader,
    WearableDetails,
    WearableTableItem,
    WearableSummary,
    TopBar,
    LoadingAnimation,
    STwoColumnLayout,
    SSearch,
  },

  props: {
    musteringEvent: Object,
    mainSnapshot: {
      type: Object,
    },
    currentModule: String,
  },

  data() {
    const { isMobile } = useScreenSize();

    return {
      searchText: "",
      sortDirection: SortDir.ASC,
      sortProperty: "fullName",
      filters: {
        showAssigned: {
          active: true,
          displayName: "Assigned",
          icon: PersonIcon,
        },
        showUnassigned: {
          active: true,
          displayName: "Unassigned",
          icon: WearableDisconnectedIcon,
        },
      },
      checkedItems: [],
      selectedWearableMac: "",
      showModal: false,
      modalType: "disembark",
      isMobile,
      searchBarPlaceholder: "Search by name, mac or role",
    };
  },

  computed: {
    ...mapState(["siteConfig"]),

    ...mapGetters(["wearables", "getPersonByWearableMac"]),

    headers() {
      return [
        {
          icon: SCheckbox,
          property: "",
          style: { flex: this.isMobile ? "0 0 28px" : "0 0 40px", justifyContent: "left" },
          name: "checkbox",
        },
        {
          title: "MAC",
          property: "mac",
          style: { flex: "0 0 45px", justifyContent: "flex-start" },
          name: "mac",
        },
        {
          icon: BatteryIcon100,
          property: "sensorData.batteryPercentage",
          style: { flex: "0 0 60px", justifyContent: "center" },
          name: "battery",
        },
        {
          icon: Temperature,
          property: "sensorData.temperature",
          style: { flex: "0 0 60px", justifyContent: "center" },
          name: "temperature",
        },
        {
          icon: Antenna,
          property: "sensorData.rssi",
          style: { flex: "0 0 60px", justifyContent: "center" },
          name: "signalstrength",
        },
        {
          title: "Issues",
          property: "unresolvedIssues",
          style: { flex: "0 0 75px", justifyContent: "center" },
          name: "issues",
        },
        {
          title: "WEARER",
          property: "fullName",
          style: { flex: "0 0 160px", justifyContent: "flex-start", marginLeft: "40px" },
          name: "name",
        },
        {
          title: "POSITION",
          property: "sensorData.nodeName",
          style: { flex: "0 0 250px", justifyContent: "flex-start" },
          name: "position",
        },
      ];
    },

    loading() {
      return !this.siteConfig;
    },

    selectedItem() {
      if (this.selectedWearableMac !== "") {
        return this.$store.getters.getWearableByMac(this.selectedWearableMac);
      } else return null;
    },

    roles() {
      return this.siteConfig.roles;
    },

    filteredWearables() {
      const searchText = this.searchText.toLowerCase();
      const sortProp = this.sortProperty;
      const arr = [];
      for (const wearable of this.wearables) {
        /**@type {DashboardWearable} */
        const person = this.getPersonByWearableMac(wearable.mac);
        wearable.unresolvedIssues = this.$store.getters.getUnresolvedIssuesOnWearable(wearable.mac);
        wearable.fullName = person?.fullName;

        /* FILTERS */
        // Toggle Unassigned
        if (!this.filters.showUnassigned.active && !person) {
          continue;
        }
        if (!this.filters.showAssigned.active && person) {
          continue;
        }

        if (
          searchText == "" ||
          (wearable.mac && getWearableShortMac(wearable.mac).toLowerCase().indexOf(searchText) !== -1) ||
          (person?.fullName && person?.fullName.toLowerCase().indexOf(searchText) !== -1) ||
          (person?.role && person?.role?.name.toLowerCase().indexOf(searchText) !== -1) ||
          (wearable.sensorData &&
            wearable.sensorData.nodeName &&
            wearable.sensorData.nodeName.toLowerCase().indexOf(searchText) !== -1)
        ) {
          arr.push(wearable);
        }
      }
      const sorted = arr.sort(nodeAndWearableSort(sortProp, this.sortDirection));

      return sorted;
    },
  },

  methods: {
    closeSelectedDetails() {
      this.selectedWearableMac = "";
      this.checkedItems = [];
    },

    handleShowModal(type) {
      this.modalType = type;
      this.showModal = true;
    },

    setSearchText(value) {
      this.searchText = value;
    },

    toggleFilter(filter) {
      this.filters[filter].active = !this.filters[filter].active;
    },

    isItemChecked(mac) {
      return this.checkedItems.indexOf(mac) !== -1;
    },

    setSortProperty(value) {
      //toggle asc/desc if clicking currently set sort property
      if (value === this.sortProperty) {
        this.sortDirection = this.sortDirection === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
      } else {
        this.sortProperty = value;
      }
    },

    handleCheckbox(val) {
      if (this.selectedWearableMac !== "") {
        this.selectedWearableMac = "";
      }
      if (val.value) {
        if (this.checkedItems.length === 0) {
          this.selectedWearableMac = val.wearable.mac;
        }
        this.checkedItems.push(val.wearable.mac);
      } else {
        this.checkedItems.splice(this.checkedItems.indexOf(val.wearable.mac), 1);
        if (this.checkedItems.length === 1) {
          this.selectedWearableMac = this.checkedItems[0];
        }
      }
    },

    toggleCheckboxes(val) {
      if (this.selectedWearableMac) {
        this.selectedWearableMac = "";
      }
      if (val) {
        for (let i = 0; i < this.filteredWearables.length; i++) {
          const wearable = this.filteredWearables[i];
          if (this.checkedItems.indexOf(wearable.mac) === -1) {
            this.checkedItems.push(wearable.mac);
          }
        }
      } else {
        this.checkedItems = [];
      }
    },

    setSelectedItem(item) {
      if (item) {
        if (this.checkedItems.length === 1 && this.checkedItems[0] === item.mac) {
          this.checkedItems = [];
          this.selectedWearableMac = "";
        } else if (this.checkedItems.length < 2) {
          this.checkedItems = [];
          this.selectedWearableMac = item.mac;
          this.checkedItems.push(item.mac);
        } else if (this.selectedWearableMac && this.selectedWearableMac !== item.mac) {
          this.selectedWearableMac = item.mac;
        } else {
          this.selectedWearableMac = "";
        }
      } else {
        // Item is null | undefined
        // => Indicates to reset selections
        this.selectedWearableMac = "";
        this.checkedItems = [];
      }
    },
  },
};
</script>

<style scoped>
.wearable-admin {
  padding: 8px;
  height: 100%;
  background: #0e191c;
}

.table-list-wrapper {
  max-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  overflow-x: auto;
  min-width: 1000px;
}

.table-list-header {
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  border-bottom: 2px solid #0e191c;
  z-index: 1;
}

.right-bar {
  height: 100%;
}

.summary-header {
  text-transform: capitalize;
  margin-bottom: 2px;
  height: 64px;
}

.summary-details,
.summary-header {
  background: var(--color-base-ui);
  padding: 24px;
}

.summary-details {
  height: 100%;
}
</style>
