import { PersonActionName } from "@/typedef";
import { GangwayDirection } from "@/types/gangwayTypes";
import DropOffIcon from "scanreach-frontend-components/src/components/icons/DropOffIcon.vue";
import DisembarkIcon from "scanreach-frontend-components/src/components/icons/DisembarkIcon.vue";
import EmbarkIcon from "scanreach-frontend-components/src/components/icons/EmbarkIcon.vue";
import WarningIcon from "scanreach-frontend-components/src/components/icons/WarningIcon.vue";
import { Component } from "vue";
import { ApiPersonAction } from "@/types/personTypes";
import { timeSince } from "scanreach-frontend-components/src/utils/timeUtils";

export function getPersonActionStateObject(
  state?: PersonActionName | GangwayDirection,
): PersonActionStateObject {
  switch (state) {
    case PersonActionName.embark:
    case GangwayDirection.OnBoard:
      return {
        userFriendlyStateName: UserFriendlyBoardingState.Embarked,
        icon: EmbarkIcon,
        iconColor: "var(--color-pop-01)",
        pillColor: "var(--color-pop-01)",
        pillTextColor: "var(--color-ui-01)",
      };
    case PersonActionName.dropoff:
    case GangwayDirection.OffBoard:
      return {
        userFriendlyStateName: UserFriendlyBoardingState.DroppedOff,
        icon: DropOffIcon,
        iconColor: "var(--color-base-white)",
        pillColor: "var(--color-base-white)",
        pillTextColor: "var(--color-ui-01)",
      };
    case PersonActionName.disembark:
      return {
        userFriendlyStateName: UserFriendlyBoardingState.Disembarked,
        icon: DisembarkIcon,
        iconColor: "var(--color-brand-01)",
        pillColor: "var(--color-ui-06)",
        pillTextColor: "var(--color-base-white)",
      };
    case PersonActionName.unresolved:
      return {
        userFriendlyStateName: UserFriendlyBoardingState.Unresolved,
        icon: WarningIcon,
        iconColor: "var(--color-pop-01)",
        pillColor: "var(--color-brand-03)",
        pillTextColor: "var(--color-base-white)",
      };
    default:
      return {
        userFriendlyStateName: UserFriendlyBoardingState.Disembarked,
        icon: DisembarkIcon,
        iconColor: "var(--color-brand-01)",
        pillColor: "var(--color-brand-01)",
        pillTextColor: "var(--color-base-white)",
      };
  }
}

export function getTimeSinceLastPersonAction(personAction?: ApiPersonAction) {
  if (personAction) {
    const timeSinceString = timeSince(personAction.dateTimeUtc);
    return timeSinceString === "now" ? timeSinceString : timeSinceString + " ago";
  } else {
    return "";
  }
}

export enum UserFriendlyBoardingState {
  Embarked = "Embarked",
  DroppedOff = "Dropped off",
  Disembarked = "Disembarked",
  Unresolved = "Unresolved",
}

export type PersonActionStateObject = {
  userFriendlyStateName: UserFriendlyBoardingState;
  icon: Component;
  iconColor: string;
  pillColor: string;
  pillTextColor: string;
};
