import { environment } from "../../environments/environment";
import customFetch from "@/helpers/customFetch";
import { CustomFetchOptions, ApiWearable, SensorDataObject } from "@/typedef";

const apiAddress = environment.apiAddress;

let fetchWearableSensorDataLock = false;

/**
 * Get specific wearable by mac
 * Might implement querying in the future.
 * @returns {Promise<ApiWearable>}
 */
export function fetchWearableByMac(macAddress: string[]): Promise<ApiWearable> {
  return customFetch(`${apiAddress}/tags/${macAddress}`, {
    method: "GET",
    credentials: "include",
  }).then((response) => {
    return response.json();
  });
}

/**
 * Get all wearables
 * Might implement querying in the future.
 * @returns {Promise<ApiWearable[]>}
 */
export function fetchWearables({ showWarning = false }: CustomFetchOptions = {}): Promise<ApiWearable[]> {
  return customFetch(
    `${apiAddress}/tags`,
    {
      method: "GET",
      credentials: "include",
    },
    showWarning,
  ).then((response) => {
    return response.json();
  });
}

/**
 * Get sensorData for list of wearableMacs
 */
export function fetchWearableSensorData(wearableMacs?: string[]): Promise<SensorDataObject> {
  return new Promise((resolve, reject) => {
    if (!fetchWearableSensorDataLock) {
      fetchWearableSensorDataLock = true;
      customFetch(`${apiAddress}/sensordata/wearable`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: wearableMacs ? JSON.stringify(wearableMacs) : "[]",
      })
        .then((response) => {
          return response.json();
        })
        .then((snapshot) => {
          fetchWearableSensorDataLock = false;
          resolve(snapshot);
        })
        .catch((error) => {
          fetchWearableSensorDataLock = false;
          reject(error);
        });
    } else {
      reject(new Error("Already waiting for this snapshot."));
    }
  });
}
