import { render, staticRenderFns } from "./POBByRole.vue?vue&type=template&id=26f4ed85&scoped=true&"
import script from "./POBByRole.vue?vue&type=script&lang=ts&"
export * from "./POBByRole.vue?vue&type=script&lang=ts&"
import style0 from "./POBByRole.vue?vue&type=style&index=0&id=26f4ed85&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26f4ed85",
  null
  
)

export default component.exports