<template>
  <li class="table-item" :class="{ selected: isSelected }">
    <div class="table-item-data" @click="$emit('itemClick')">
      <div v-for="(property, index) of properties" :style="property.style" :key="index">
        <span
          v-html="getFormattedContent(item[property.property], true)"
          v-tooltip="{
            content:
              getFormattedContent(item[property.property]).length > 20
                ? getFormattedContent(item[property.property])
                : '',
            placement: 'bottom-center',
          }"
        ></span>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    checked: Boolean,
    isSelected: Boolean,
    searchText: String,
    properties: Array,
  },

  computed: {},

  methods: {
    handleCheckbox(value) {
      this.$emit("checkboxChange", { value, item: this.item });
    },
    /**
     * @returns {string}
     */
    getFormattedContent(val, highlighting = false) {
      if (!val) return "";

      if (Array.isArray(val)) {
        val = val.join(", ");
      }

      if (!highlighting) return val;

      const iQuery = new RegExp(this.searchText, "ig");
      return val.toString().replace(iQuery, function (matchedTxt) {
        return (
          "<span class='highlight' style='background: var(--color-base-white); color: #333;'>" +
          matchedTxt +
          "</span>"
        );
      });
    },
  },
};
</script>

<style scoped>
li {
  margin-top: 2px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background-color: var(--color-base-ui);
  transition: all 0.2s ease-in-out;
}
li:hover {
  background-color: var(--color-ui-05);
}

.table-item-data {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 72px;
  padding: 20px 16px 20px 0;
  min-width: 0px;
}

.table-item-data div {
  text-overflow: ellipsis;
  overflow: hidden;
}

.table-item-data div span {
  cursor: text;
}

.table-item {
  cursor: pointer;
}

.checkbox {
  flex: 0 0 40px;
  min-width: 0px;
}
.name > * {
  display: inline;
}

.checkbox {
  display: flex;
  align-items: center;
}

.mac {
  color: #0cebff;
}

.battery,
.temperature,
.signal {
  text-align: center;
}
li.selected {
  background-color: var(--color-brand-04);
}

li.table-item-disembark {
  background-color: var(--color-ui-02);
}
li.table-item-disembark > div {
  color: var(--color-brand-01);
}
li.table-item-dropoff > div {
  color: var(--color-brand-01);
}
</style>
