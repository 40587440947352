import { useStore } from "@/store/desktopStore";
import { PeopleCount, PersonActionName } from "@/typedef";
import { ApiPersonRole, DashboardPerson } from "@/types/personTypes";
import { Ref, computed } from "vue";

export default function usePobByRoleCount(roles: Ref<ApiPersonRole[]>, peopleCount: Ref<PeopleCount>) {
  const store = useStore();
  const people = computed(() => store.getters.people as DashboardPerson[]);
  const dropOffCount = computed(
    () => people.value.filter((p) => p.lastPersonAction?.action == PersonActionName.dropoff).length,
  );
  const orderedPobCountByRole = computed(() => {
    const rolesWithCount = roles.value?.map((role: ApiPersonRole) => {
      const count = pobByRoleName(role.name);
      return {
        ...role,
        ...count,
      };
    });

    rolesWithCount.push({
      id: "unknown-role",
      name: "Undefined role",
      totalCount: unknownPOBRoleCount.value,
      notMustered: 0,
      mustered: 0,
    });

    return rolesWithCount.filter((item) => item.totalCount > 0).sort((a, b) => b.totalCount - a.totalCount);
  });

  const unknownPOBRoleCount = computed(() => {
    return (
      peopleCount.value.total -
      (Object.values(peopleCount.value.musteredByRole).reduce((inc, acc) => (acc += inc), 0) +
        Object.values(peopleCount.value.notMusteredByRole).reduce((inc, acc) => (acc += inc), 0))
    );
  });

  function pobByRoleName(roleName: string): { mustered: number; notMustered: number; totalCount: number } {
    const lowerCaseRoleName = roleName.toLowerCase();
    const count = {
      mustered: 0,
      notMustered: 0,
      totalCount: 0,
    };
    if (Object.prototype.hasOwnProperty.call(peopleCount.value.musteredByRole, lowerCaseRoleName)) {
      const c = peopleCount.value.musteredByRole
        ? peopleCount.value.musteredByRole[lowerCaseRoleName] ?? 0
        : 0;
      count.mustered += c;
      count.totalCount += c;
    }
    if (Object.prototype.hasOwnProperty.call(peopleCount.value.notMusteredByRole, lowerCaseRoleName)) {
      const c = peopleCount.value.notMusteredByRole
        ? peopleCount.value.notMusteredByRole[lowerCaseRoleName] ?? 0
        : 0;
      count.notMustered += c;
      count.totalCount += c;
    }
    return count;
  }

  return {
    dropOffCount,
    orderedPobCountByRole,
    unknownPOBRoleCount,
  };
}
