import { Guid, ApiWearable, PersonActionName, ApiChangeMessageBase, DashboardIssue } from "@/typedef";

export interface DashboardPerson extends ApiPerson {
  wearableMac: string | null | undefined;
  unresolvedIssueIds?: string[];
  unresolvedIssues?: DashboardIssue[];
}

export interface ApiPerson {
  id: Guid;
  fullName?: string | null;
  dateOfBirth?: Date | null;
  cabinNr?: string | null;
  bunkNr?: string | null;
  role?: ApiPersonRole | null;
  wearable?: ApiWearable | null;
  lastPersonAction?: ApiPersonAction | null;
  externalPersonReference?: ApiExternalPersonReference | null;
  deletedUtcDateTime?: Date | null;
}

export interface ApiPersonRole {
  id: Guid;
  name: string;
}

export interface ApiPersonAction {
  id: Guid;
  dateTimeUtc: Date;
  personId: Guid;
  action: PersonActionName;
  location: string | null;
  actionSource: PersonActionSource;
}

export enum PersonActionSource {
  MANUAL = "manual",
  GANGWAY_APPROVAL = "gangwayApproval",
  GANGWAY_AUTO = "gangwayAuto",
  EXTERNAL_SYSTEM = "externalSystem",
}

export interface ApiExternalPersonReference {
  id: Guid;
  externalPersonId: string;
  vendor: ExternalSystemVendor;
  isExternalSystemOwnerOfData: boolean;
  isExternalSystemResponsibleForConnectingWearable: boolean;
  isExternalSystemResponsibleForEmbarking: boolean;
  isExternalSystemResponsibleForDropOff: boolean;
  isExternalSystemResponsibleForDisEmbarking: boolean;
}

export enum ExternalSystemVendor {
  OCS = "OCS",
  UNISEA = "UNISEA",
}

export interface ExternalSyncResultReadModel {
  vendor: ExternalSystemVendor;
  lastSuccessfulSyncTimeStamp: Date;
}

export interface PersonChangeMessage extends ApiChangeMessageBase, ApiPerson {
  deletedUtcDateTime?: Date;
}
