var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wearable-summary"},[_c('div',{staticClass:"summary-header"},[_c('div',{staticClass:"header-and-sync-container"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v(_vm._s(_vm.headingText))])]),(_vm.externalSystemName && _vm.externalSystemLastSync)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: `Syncs with ${_vm.externalSystemName} every 1 min. Wait 2-5 min and check again if a person is missing from the list`,
          placement: 'top-center',
          trigger: _vm.isMobile ? 'click' : 'hover',
        }),expression:"{\n          content: `Syncs with ${externalSystemName} every 1 min. Wait 2-5 min and check again if a person is missing from the list`,\n          placement: 'top-center',\n          trigger: isMobile ? 'click' : 'hover',\n        }"}],staticClass:"source-system-decorator",style:({
          textDecoration: _vm.isMobile ? 'underline' : undefined,
        })},[_vm._v(" · "+_vm._s(_vm.externalSystemName)+" sync ("+_vm._s(_vm.externalSystemLastSync)+") ")]):_vm._e()]),(_vm.checkedItems.length > 0)?_c('span',{staticClass:"close-icon",on:{"click":function($event){return _vm.$emit('close')}}},[_c('CloseIcon',{attrs:{"size":"24","iconColor":"var(--color-brand-01)"}})],1):_vm._e()]),_c('div',{staticClass:"wearable-role-info"},[_c('chart-donut',{attrs:{"initialValues":_vm.donutValues}}),(_vm.mostSeriousCrewIssue)?_c('EventPill',{staticClass:"sync-status-pill",attrs:{"event":_vm.mostSeriousCrewIssue,"pillTextColor":"var(--color-ui-01)"}}):_vm._e(),_c('ul',{staticClass:"info-list info-personnel"},_vm._l((_vm.donutValues),function(role){return _c('li',{key:'role-' + role.name},[_c('div',{staticClass:"category-circle",style:({ background: role.color })}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: role.name,
            delay: {
              show: 500,
            },
          }),expression:"{\n            content: role.name,\n            delay: {\n              show: 500,\n            },\n          }"}],staticClass:"label"},[_vm._v(" "+_vm._s(role.name)+" ")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(role.count))])])}),0)],1),_c('div',{staticClass:"wearable-status-info"},[_c('ul',{staticClass:"info-list"},_vm._l((_vm.additionalInfo),function(value,name){return _c('li',{key:name},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.userFriendlyBoardingState(name)))]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(value))])])}),0),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.unassignWearablesTooltip),expression:"unassignWearablesTooltip"}],staticStyle:{"margin-bottom":"24px"}},[_c('NormalButton',{attrs:{"disabled":!_vm.isUnassignMultipleWearablesAllowed},nativeOn:{"click":function($event){return _vm.$emit('showModal', 'unassignWearable')}}},[_vm._v(" Unassign wearables ")])],1)]),_c('div',{staticClass:"boarding-buttons"},[_c('BoardingButtons',{attrs:{"people":_vm.checkedPeople},on:{"disembark":function($event){return _vm.$emit('showModal', 'disembark')},"dropOff":function($event){return _vm.$emit('showModal', 'dropOff')},"embark":function($event){return _vm.$emit('showModal', 'embark')}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }