import { ApiEvent, PersonActionName } from "@/typedef";
import { DashboardPerson } from "@/types/personTypes";
import { ApiNode, NodeOperationMode } from "scanreach-frontend-components/src/types/ApiNode.type";
import { Issue, IssueType } from "scanreach-frontend-components/src/types/Issue.type";

/**
 * Returns the last 4 digits in a mac
 * @param {string} wearableMac
 */
export function getWearableShortMac(wearableMac: string) {
  return wearableMac ? wearableMac.slice(-5).toUpperCase() : null;
}

/**
 * Check if a person is active or not
 * @param person
 */
export function isPersonActive(person?: DashboardPerson | null) {
  return person?.lastPersonAction?.action == PersonActionName.embark;
}

/**
 * Check if a node has location coordinates or not
 * @param node
 */
export function isNodePlaced(node: ApiNode | null) {
  return !!node?.x && !!node?.y;
}

/**
 * Check if a node is musterstation or not
 * @param node
 */
export function isNodeAMusterstation(node: ApiNode | null) {
  return node?.musterStation != null;
}

/**
 * @Deprecated
 * Check if a alert is connected to active hardware
 * @param alert
 */
export function isAlertOnActiveHardware(alert: ApiEvent, person?: DashboardPerson): boolean | null {
  if (alert.tag) {
    return isPersonActive(person);
  }
  if (alert.node) {
    return isNodePlaced(alert.node);
  }
  return null;
}

/**
 * Check if a issue is connected to active hardware
 */
export function isIssueOnActivePersonOrNode(
  issue: Issue,
  person?: DashboardPerson,
  node?: ApiNode,
): boolean | null {
  if (issue.wearable) {
    if (person) {
      return isPersonActive(person);
    } else {
      return false;
    }
  }
  if (node) {
    return isNodePlaced(node) || node?.operationMode == NodeOperationMode.GANGWAY; // issues on gangway nodes should show altough never placed in map
  }
  if (issue.personId) {
    return true; // Currently all issues connected to only person should show
  }
  return null; // return null when issue is not connected to node or person
}

/**
 * Returns true if the issue is related to wearables
 * @param issueType Type of issue
 */
export function isIssueRelatedToWearable(issueType: IssueType): boolean {
  return [IssueType.WEARABLE_MISSING, IssueType.WEARABLE_BATTERY_LOW].includes(issueType as any);
}

/**
 * Returns true if the issue is related to nodes
 * @param issueType Type of issue
 */
export function isIssueRelatedToNode(issueType: IssueType): boolean {
  return [
    IssueType.NODE_MISSING,
    IssueType.NODE_RUNNING_ON_BATTERY,
    IssueType.ROOT_NODE_MISSING,
    IssueType.REPORTING_NODE_NOT_PLACED_IN_MAP,
    IssueType.NODE_WEAK_RSSI,
    IssueType.NODE_WEAK_LINK,
  ].includes(issueType);
}

/**
 * Returns true if the issue is related to Mesh Debug Tool OWC network.
 * These issues are only shown in /nodeadmin or in /status if OWC filter is enabled.
 * @param issueType Type of issue
 */
export function isIssueRelatedToOWCDebug(issueType: IssueType): boolean {
  return [IssueType.NODE_WEAK_RSSI, IssueType.NODE_WEAK_LINK].includes(issueType);
}
