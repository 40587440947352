import { SortDir } from "@/compositions/useSort";
import { get } from "lodash";

export function sortList(list: unknown[], sortProp: "" | string, sortDir: SortDir) {
  if (sortProp == "") {
    return list;
  }
  return list.sort((a, b) => {
    const aSort = get(a as never, sortProp);
    const bSort = get(b as never, sortProp);

    return normalSort(aSort, bSort, sortDir);
  });
}

export function normalSort(aSort: unknown, bSort: unknown, sortDir: SortDir = SortDir.ASC) {
  if (aSort === bSort) return 0; // Equal values

  // Null and undefined values last in list
  if (aSort === null || aSort === undefined) return 1;
  if (bSort === null || bSort === undefined) return -1;

  // Sort on string value
  if (typeof aSort === "string" && typeof bSort === "string") {
    const aSortLower = aSort.toLowerCase();
    const bSortLower = bSort.toLowerCase();
    if (aSortLower > bSortLower) return sortDir === SortDir.ASC ? 1 : -1;
    else if (aSortLower < bSortLower) return sortDir === SortDir.ASC ? -1 : 1;
    else return 0;
  } else if (
    (typeof aSort === "number" && typeof bSort === "number") ||
    (aSort instanceof Date && bSort instanceof Date)
  ) {
    return sortNumber(Number(aSort), Number(bSort), sortDir);
  } else {
    // No clue what to do at this point
    // Unsupported type to sort
    console.error(
      "Unsupported type to sort. Please, investigate this.",
      typeof aSort,
      typeof bSort,
      aSort,
      bSort,
    );
    return 0;
  }
}

function sortNumber(aSort: number, bSort: number, sortDir: SortDir) {
  if (sortDir === SortDir.ASC) {
    return aSort - bSort;
  } else {
    return bSort - aSort;
  }
}
