<template>
  <div class="filters">
    <button
      v-for="filterName in Object.keys(filters)"
      v-bind:key="filterName"
      :class="{ disabled: isToggleLookingDisabled(filterName) }"
      @click="$emit('toggleFilter', filterName)"
    >
      <div class="button-content">
        <component
          v-show="filters[filterName].icon"
          :is="filters[filterName].icon"
          size="16"
          iconColor="var(--color-pop-01)"
        />
        <!--
          Tooltip leads to hard to press buttons as tooltip does not fit inside topBar and overlays the button
          <p v-tooltip="{ content: filters[filterName].title, offset: 10 }">
        -->
        <p :title="filters[filterName].title ?? undefined">
          {{ filters[filterName].displayName.toUpperCase() }}
        </p>
        <div class="filter-circle"></div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    filters: Object,
    isMusteringActive: Boolean,
  },
  methods: {
    isToggleLookingDisabled(filteringToggleName) {
      if (this.isMusteringActive && filteringToggleName === "showMusterstations") {
        return false;
      } else {
        return !this.filters[filteringToggleName].active;
      }
    },
  },
};
</script>

<style scoped>
.filters {
  min-width: fit-content;
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
}
.filters > button {
  background: none;
  color: #0cebff;
  border: 1px solid #2291a0;
  border-radius: 16px;
  padding: 0px 12px 0 12px;
  height: 32px;
  font-size: 10px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  flex-shrink: 0;
}
.filters > button:hover {
  border-color: var(--color-pop-01);
}

.filter-circle {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-left: 8px;
  position: relative;
}

.filter-circle::before {
  width: 12px;
  height: 12px;
  background: var(--gradient-skeuomorph);
  mix-blend-mode: overlay;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  border-radius: 6px;
}

.filter-circle::after {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #0cebff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  content: "";
  transition: all 0.2s ease-in-out;
}

.filters > button svg * {
  transition: all 0.2s ease-in-out;
}
.filters > button.disabled {
  color: var(--color-pop-03);
}
.filters > button.disabled svg * {
  stroke: var(--color-pop-03);
}
.filters > button.disabled .filter-circle::after {
  opacity: 0.25;
}
.button-content {
  display: flex;
  align-items: center;
}

.button-content > svg {
  margin-right: 12px;
}
</style>
