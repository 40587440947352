import { get } from "lodash";
import { getWearableShortMac } from "./hardwareHelpers";
import { RoleName, DashboardNode, DashboardWearable, DashboardIssue } from "@/typedef";
import { ApiPerson } from "@/types/personTypes";
import { SortDir } from "../compositions/useSort";
import { Issue, Severity } from "scanreach-frontend-components/src/types/Issue.type";
/**
 *
 * @param sortProp
 * @param sortDir
 */
export function nodeAndWearableSort(sortProp: string, sortDir: SortDir, nullDefaultValue: unknown = null) {
  return function (
    a: DashboardWearable | DashboardNode | ApiPerson,
    b: DashboardWearable | DashboardNode | ApiPerson,
  ) {
    let aSort = get(a, sortProp);
    let bSort = get(b, sortProp);
    if (sortProp === "mac" || sortProp.endsWith("mac")) {
      aSort = getWearableShortMac(aSort);
      bSort = getWearableShortMac(bSort);
    }
    if (sortProp.indexOf("unresolvedIssues") > -1) {
      aSort = getSortValueFromIssues(aSort);
      bSort = getSortValueFromIssues(bSort);
    }

    if (aSort === bSort) return 0; // Equal values

    // Null and undefined values last in list
    if (aSort === null || aSort === undefined) {
      if (nullDefaultValue !== null) {
        aSort = nullDefaultValue;
      } else {
        return 1;
      }
    }
    if (bSort === null || bSort === undefined) {
      if (nullDefaultValue !== null) {
        bSort = nullDefaultValue;
      } else {
        return -1;
      }
    }

    // Sort on string value
    if (typeof aSort === "string" && typeof bSort === "string") {
      aSort = aSort.toLowerCase();
      bSort = bSort.toLowerCase();
      if (aSort > bSort) return sortDir === SortDir.ASC ? 1 : -1;
      else if (aSort < bSort) return sortDir === SortDir.ASC ? -1 : 1;
      else return 0;
    } else {
      if (aSort === "") aSort = 0;
      if (bSort === "") bSort = 0;
      if (sortDir === SortDir.ASC) {
        return aSort - bSort;
      } else {
        return bSort - aSort;
      }
    }
  };
}

export function getUserSortingStrategy(sortProp: string, sortDir: SortDir) {
  const direction = { [SortDir.ASC]: 1, [SortDir.DESC]: -1 }[sortDir];
  let comparison;

  return function (a: any, b: any) {
    a = get(a, sortProp);
    b = get(b, sortProp);

    switch (sortProp) {
      case "roles":
        a = a[0];
        b = b[0];
        comparison = getUserRoleRank(a) > getUserRoleRank(b) ? 1 : -1;
        break;
      default:
        a = typeof a === "string" ? a.toLowerCase() : a;
        b = typeof b === "string" ? b.toLowerCase() : b;
        comparison = a > b ? 1 : -1;
    }

    if (a === b) comparison = 0;
    if (a === null || a === undefined) comparison = -1;
    if (b === null || b === undefined) comparison = 1;

    return comparison * direction;
  };
}

function getUserRoleRank(userRole: RoleName) {
  switch (userRole) {
    case RoleName.admin:
      return 1;
    case RoleName.configurator:
      return 2;
    case RoleName.operator:
      return 3;
    default:
      return Number.MAX_VALUE;
  }
}

/**
 * Used for comparing two people/wearables/nodes when sorting on unresolvedIssues
 * @param issues List of issues connected to
 */
function getSortValueFromIssues(issues: DashboardIssue[]) {
  if (!issues || issues.length == 0) return 1;
  const highestSeverity = highestSeverityInIssues(issues);
  if (highestSeverity === null) {
    return 1;
  } else if (highestSeverity == Severity.DISTRESS_ALARM) {
    return severityToInt.get(highestSeverity);
  }
  const isConnectedNodeOrPersonActive = issues.some((i) => i.isConnectedNodeOrPersonActive);
  if (!isConnectedNodeOrPersonActive) {
    return 1.5;
  }
  return severityToInt.get(highestSeverity);
}

export function highestSeverityInIssues(issues: Issue[]): Severity | null {
  let highestSeverityAsInt = 0;
  for (const issue of issues) {
    const issueSeverityAsInt = severityToInt.get(issue.severity);
    if (issueSeverityAsInt && issueSeverityAsInt > highestSeverityAsInt) {
      highestSeverityAsInt = issueSeverityAsInt;
    }
  }
  if (highestSeverityAsInt == 0) {
    return null;
  }
  return severityIntToSeverity(highestSeverityAsInt, severityToInt);
}

const severityToInt: Map<Severity, number> = new Map<Severity, number>();
severityToInt.set(Severity.DISTRESS_ALARM, 5);
severityToInt.set(Severity.SEVERE_SYSTEM_ALARM, 4);
severityToInt.set(Severity.SYSTEM_ALARM, 3);
severityToInt.set(Severity.SYSTEM_ALERT, 2);

function severityIntToSeverity(
  severityAsInt: number,
  severityToIntMap: Map<Severity, number>,
): Severity | null {
  for (const [severity, intValue] of severityToIntMap) {
    if (intValue == severityAsInt) {
      return severity;
    }
  }
  return null;
}

export function sortIssuesByProp(sortProp: "severity" | "activatedTime", sortDir: SortDir) {
  const sortFactor = { [SortDir.ASC]: 1, [SortDir.DESC]: -1 };

  const severityOrder: any = {};
  severityOrder[Severity.DISTRESS_ALARM] = 0;
  severityOrder[Severity.SEVERE_SYSTEM_ALARM] = 1;
  severityOrder[Severity.SYSTEM_ALARM] = 2;
  severityOrder[Severity.SYSTEM_ALERT] = 3;

  return (x: Issue, y: Issue) => {
    // picking the right property from the items
    const a = get(x, sortProp);
    const b = get(y, sortProp);
    // preemptive comparisons
    if (a === b) return 0;
    if (a === null || a === undefined) return 1 * sortFactor[sortDir];
    if (b === null || b === undefined) return -1 * sortFactor[sortDir];
    // actual comparisons
    switch (sortProp) {
      case "severity":
        return (severityOrder[x.severity] > severityOrder[y.severity] ? 1 : -1) * sortFactor[sortDir];
      case "activatedTime":
        return (x.activatedTime < y.activatedTime ? 1 : -1) * sortFactor[sortDir];
      default:
        throw new Error(`Undefined sort property ${sortProp}`);
    }
  };
}

export function getFormattedContent(val?: string, searchText?: string, highlighting = false): string {
  if (!val) return "";

  if (!searchText) return val;

  if (Array.isArray(val)) {
    val = val.join(", ");
  }

  if (!highlighting) return val;

  const iQuery = new RegExp(searchText, "ig");
  return val.toString().replace(iQuery, function (matchedTxt) {
    return (
      "<span class='highlight' style='background: var(--color-base-white); color: #333;'>" +
      matchedTxt +
      "</span>"
    );
  });
}
